<template>
  <div class="mainform">
    <!-- 创建索赔单 -->
    <div class="mainHeader">
      创建索赔单 <span @click="back" class="back">返回></span>
    </div>
    <div class="qualityBtn">
          <el-button
            type="primary"
            size="medium"
            plain
            @click="choosequalityBtn"
            >+选择8D报告及FA</el-button
          >
        </div>
    <div class="form mg-form fbxj-table">
      <el-form
        class="bascform"
        ref="BasicForm"
        :model="BasicForm"
        label-width="140px"
        :rules="rules"
      >
        <div class="content">
          <div class="col col4">
            <el-form-item label="单据单号" prop="qualityCode">
              <el-input
                v-model="BasicForm.qualityCode"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔类型" required prop="claimType">
              <el-select
                v-model="BasicForm.claimType"
                filterable
                placeholder="请选择"
              >
                <el-option label="来料检验索赔" value="1"></el-option>
                <el-option label="售后索赔" value="2"></el-option>
                <el-option label="生产过程索赔" value="3"></el-option>
                <el-option label="行政索赔" value="4"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商" prop="supplier" :rules="{ required: true, message: '请选择供应商', trigger: 'blur' }">
            <el-select size="small" filterable remote
              :remote-method="requestSupplierOption"
              :loading="supplierLoading"
              @change="supChange"
               v-model="BasicForm.supplier">
              <el-option v-for="(item) in supplierOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔来源">
              <el-select
                v-model="BasicForm.claimSource"
                filterable
                placeholder="请选择"
              >
                <el-option label="来料" value="1"></el-option>
                <el-option label="售后" value="2"></el-option>
                <el-option label="生产线" value="3"></el-option>
                <el-option label="研发" value="4"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称">
              <el-input
                placeholder="请输入"
                v-model="BasicForm.companyName"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="要求反馈日期">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="BasicForm.feedbackDate"
                @change="feedbackchange"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="采购员" prop="buyerName">
              <el-select
                v-model="BasicForm.buyerName"
                filterable
                placeholder="请选择"
              >
                <el-option v-for="item in purchaser" :label="`${item.name}-${item.username}`" :value="`${item.name}-${item.username}`" :key="item.id"></el-option>
              </el-select>
              <!-- <el-input
                placeholder="请输入"
                v-model="BasicForm.buyerName"
              ></el-input> -->
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="币种" required prop="currency">
              <el-select
                v-model="BasicForm.currency"
                filterable
                placeholder="请选择"
              >
                <el-option label="RMB" value="1"></el-option>
                <el-option label="USD" value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="索赔说明" prop="claimDesc">
              <el-input
                type="textarea"
                v-model="BasicForm.claimDesc"
                placeholder="请填写备注"
              ></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="质量责任通知单" required prop="fileInfoList">
              <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :file-list="BasicForm.fileInfoList"
              >
                <el-button size="small" type="primary" plain
                  >上传附件</el-button
                >
              </el-upload>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top">
            <el-form-item label="索赔总额" prop="claimMoney">
              <el-input
                v-model="BasicForm.claimMoney"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="action">
        <el-button
          class="submit"
          type="primary"
          size="medium"
          @click="preservation"
          >确认</el-button
        >
      </div>
    </div>
    <dialog-box
      ref="dialogbox"
      dialogWidth="60%"
      :dialogShow="dialogShow"
      @handleClose="handleClose"
      title="选择8D报告及FA"
      componentName="ChoosequalityDialogFA"
      @affirm="affirm"
      :buttonData="[
        { label: '关闭', action: 'handleClose', size: 'mini' },
        { label: '确认', action: 'affirm', size: 'mini' },
      ]"
    >
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request, baseURL, requestForm } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'AddClaimForm',
  props: [],
  data: function () {
    return {
      rules: {
        qualityCode: [{ required: true, message: '请输入单据单号', trigger: 'blur,change' }],
        claimType: [{ required: true, message: '请选择索赔类型', trigger: 'blur,change' }],
        buyerName: [{ required: true, message: '请选择采购员', trigger: 'blur,change' }],
        claimDesc: [{ required: true, message: '请填写索赔说明', trigger: 'blur,change' }],
        fileInfoList: [{ required: true, message: '请上传质量责任通知单', trigger: 'blur,change' }],
        claimMoney: [{ required: true, message: '请输入索赔总额', trigger: 'blur,change' }],
        currency: [{ required: true, message: '选择币种', trigger: 'blur,change' }]
      },
      BasicForm: {
        fileInfoList: []
      },
      supplierOptions: [],
      supplierLoading: false,
      dialogShow: false,
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      purchaser: []
    }
  },
  created () {
    var erpCode = this.$store.state.loginInfo.erpCode
    if (erpCode) {
      request('/api/supplier/basicData/getByErpCode/' + erpCode, 'GET').then(
        (response) => {
          if (response.code === '200') {
            this.BasicForm.supplierName = response.data.name
          }
        }
      )
    }
    // 获取采购员
    requestForm('/api/system/role/getUserByRoleId', 'post', { roleId: '540776582185422848' }).then((response) => {
      if (response.code === '200') {
        this.purchaser = response.data || []
      }
    })
  },
  mounted () {
    this.BasicForm.currency = 'RMB'
  },
  watch: {
    '$store.state.loginInfo': function () {
      var erpCode = this.$store.state.loginInfo.erpCode
      if (erpCode) {
        request('/api/supplier/basicData/getByErpCode/' + erpCode, 'GET').then(
          (response) => {
            if (response.code === '200') {
              this.BasicForm.supplierName = response.data.name
            }
          }
        )
      }
    }
  },
  methods: {
    supChange (data) {
      for (const item of this.supplierOptions) {
        if (item.value === data) {
          this.BasicForm.supplierCode = item.erpCode
          this.BasicForm.supplierName = item.value
          console.log(item)
        }
      }
    },
    requestSupplierOption (val) {
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({
              key: `${item.name}`,
              label: `${item.name}`,
              value: `${item.name}`,
              erpCode: `${item.erpCode}`
            })
          }
        }
        this.supplierLoading = false
      })
    },
    choosequalityBtn () {
      // 选择质量通知单按钮
      console.log(this.BasicForm.supplier)
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
    },
    affirm () {
      // 选择质量通知单确认按钮
      var newListData =
        this.$refs.dialogbox.$refs.content.$refs.table.selectData
      if (newListData.length > 0) {
        this.newList =
          this.$refs.dialogbox.$refs.content.$refs.table.selectData
        for (const item of this.newList) {
          console.log('hhh', item)
          this.BasicForm.supplier = item.supplierName
          this.BasicForm.companyName = item.companyName
          this.BasicForm.supplierCode = item.supplierCode
          this.BasicForm.supplierName = item.supplierName
        }
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的内容',
          type: 'warning'
        })
      }
    },
    // 确认
    preservation () {
      var obj = { ...this.BasicForm }
      console.log('obj', obj)

      this.$refs.BasicForm.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请输入全部的必选项',
            type: 'warning'
          })
        } else {
          request('/api/quality/claim/save', 'post', obj).then((response) => {
            if (response.code === '200') {
              this.$message({
                message: '成功',
                type: 'success'
              })
              this.$router.push('/QualitySynergy/Responsibility')
            }
          })
        }
      })
    },

    feedbackchange () {
      if (this.BasicForm.feedbackDate) {
        this.BasicForm.feedbackDate = dayjs(this.BasicForm.feedbackDate).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      } else {
        this.BasicForm.feedbackDate = ''
      }
    },
    // 返回
    back: () => {
      window.history.back()
    },
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
      /* exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name
            .substring(file.name.lastIndexOf('.') + 1)
            .toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        }
      ) */
    },
    handleRemove (file) {
      for (var i = 0; i < this.BasicForm.fileInfoList.length; i++) {
        if (this.BasicForm.fileInfoList[i].id === file.id) {
          this.BasicForm.fileInfoList.splice(i, 1)
        }
      }
    },
    beforeRemove (file, fileInfoList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileInfoList) {
      if (response.code === '200') {
        response.data.forEach((element) => {
          this.BasicForm.fileInfoList.push(element)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/elform.scss";
.fbxj-table {
  .xunjia-news {
    color: #666;
    font-size: 16px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    span {
      display: inline-block;
      width: 3px;
      background: #ccc;
      vertical-align: -4px;
      height: 20px;
      background: #4487fa;
      margin-right: 5px;
    }
  }
  .action {
    margin: 10px 0 30px;
  }
  .ssyj {
    padding: 20px;
    border: 1px dashed #ccc;
    color: #666;
    font-size: 14px;
  }
  .ssyjsm {
    margin: 10px 0;
  }
  .yjcont span {
    padding-right: 15px;
  }
  .yjqm {
    text-align: right;
  }
  .yjqm span {
    padding-left: 15px;
  }
  .fkpz {
    padding: 20px 0 0;
  }
}
.qualityBtn {
  margin: 15px 20px;
}
</style>
